import React from "react";
import styled from "styled-components";
import Layout from "../lib/Layout";
import { Button } from "../lib/Button";
import Grid from "../lib/Grid";
import Page from "../lib/Page";
import Paragraph, { SIZES } from "../lib/Paragraph";
import { theme } from "../lib/theme";
import Title, { HEADINGS } from "../lib/TItle";
import { Wrapper } from "../lib/Wrapper";

const SampleGrid = styled(Grid)`
  grid-template-columns: repeat(12, 8rem);
  margin-bottom: 4rem;
`;

const ButtonGrid = styled(Grid)`
  grid-row-gap: 2rem;
  grid-template-columns: 18.1rem;
  margin-bottom: 4rem;
`;

const Column = styled.div`
  background: ${theme.color.red[900]};
  height: 10rem;
`;

const SampleTitle = styled(Title)`
  margin-bottom: 3.2rem;
`;

const SampleParagraph = styled(Paragraph)`
  margin-bottom: 1.2rem;
`;

const Library = () => {
  return (
    <Layout>
      <Page>
        <Wrapper>
          <SampleTitle size={HEADINGS.H1}>Desktop Grid</SampleTitle>
          <SampleGrid>
            {Array.from({ length: 12 }).map(() => (
              <Column />
            ))}
          </SampleGrid>
          <SampleTitle size={HEADINGS.H1}>
            H1 - Poppins Semibold 40px / 48px line-height
          </SampleTitle>
          <SampleTitle size={HEADINGS.H2}>
            H2 - Poppins Semibold 32px / 36px line-height
          </SampleTitle>
          <SampleTitle size={HEADINGS.H3}>
            H3 - Poppins Semibold 24px / 36px line-height
          </SampleTitle>
          <SampleTitle size={HEADINGS.H4}>
            H4 - Poppins Semibold 16px / 24px line-height
          </SampleTitle>
          <SampleTitle size={HEADINGS.H5}>
            H5 - Poppins Semibold 13px / 18px line-height
          </SampleTitle>
          <SampleParagraph size={SIZES.S}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio
            ratione necessitatibus et similique, fugiat debitis, reiciendis
            impedit culpa nesciunt perspiciatis saepe voluptatem aspernatur nemo
            vel nobis inventore quisquam illum deleniti!
          </SampleParagraph>
          <SampleParagraph size={SIZES.M}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio
            ratione necessitatibus et similique, fugiat debitis, reiciendis
            impedit culpa nesciunt perspiciatis saepe voluptatem aspernatur nemo
            vel nobis inventore quisquam illum deleniti!
          </SampleParagraph>
          <ButtonGrid>
            <Button>Request button</Button>
            <Button secondary>Request button</Button>
            <Button disabled>Request button</Button>
          </ButtonGrid>
        </Wrapper>
      </Page>
    </Layout>
  );
};

export default Library;
